import { Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { CALLS_ANALYSIS_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from './shared/constants/routes.constants';
import { unAuthenticatedGuard } from './shared/route-guards/un-authenticated.guard';
import { authenticatedGuard } from './shared/route-guards/authenticated.guard';
import { removeUrlTokenGuard } from './shared/route-guards/remove-url-token.guard';

export const routes: Routes = [
  {
    path: LOGIN_ROUTE,
    loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
    canActivate: [unAuthenticatedGuard],
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        redirectTo: HOME_ROUTE,
        pathMatch: 'full',
      },
      {
        path: HOME_ROUTE,
        loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
        canActivate: [authenticatedGuard, removeUrlTokenGuard],
      },
      {
        path: CALLS_ANALYSIS_ROUTE,
        loadComponent: () => import('./call-analysis/call-analysis.component').then((m) => m.CallAnalysisComponent),
        canActivate: [authenticatedGuard],
      },
    ],
  },
];
