import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { HOME_PATH } from '../constants/routes.constants';
import { AuthService } from '../../core/auth.service';

export const unAuthenticatedGuard: CanActivateFn = async (): Promise<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isAuthenticated = await authService.guardAuthenticated();
  if (!isAuthenticated) {
    return true;
  } else {
    router.navigate([HOME_PATH]);
    return false;
  }
};
