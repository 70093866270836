import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject } from '@angular/core';

export const removeUrlTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router: Router = inject(Router);
  const currentPath = state.url;
  if (currentPath && currentPath.includes('access_token')) {
    const urlWithoutToken = currentPath.split('#')[0];
    return of(router.createUrlTree([urlWithoutToken]));
  }
  return of(true);
};
